import React from "react";
import styled from "styled-components";
import { breakpoints } from "../styles/breakpoints";

const ToastContainer = styled.div`
	width: 300px;
	background-color: #cee0a0;
	position: fixed;
	top: 20px;
	right: -350px;
	z-index: 10;
	padding: 16px 20px;
	border-radius: 6px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
	line-height: 1.6;
	transition: right 0.2s, opacity 0.2s;
	p {
		color: #6b8628;
		margin-bottom: 0;
	}
	@media (max-width: ${breakpoints.xSmall}) {
		top: 0;
		width: calc(100% - 40px);
		right: -405px;
	}
	&.show {
		right: 20px;
		@media (max-width: ${breakpoints.xSmall}) {
			right: 0;
		}
	}
`;

export default function Toaster() {
	return (
		<ToastContainer id="toaster">
			<p>
				<strong>Thanks for your message!</strong>
				<br /> We'll be in touch shortly.
			</p>
		</ToastContainer>
	);
}
