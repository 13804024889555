import React from "react";
import styled, { keyframes } from "styled-components";
import { Link, navigate } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { breakpoints } from "../styles/Breakpoints";

import { useInView } from 'react-intersection-observer'

// Create the keyframes
const fadeLeft = keyframes`
  from {
    transform: translateY(50px) rotate(-2deg);
	opacity: 0;
  }

  to {
	opacity: 1;
    transform: translateY(0) rotate(-2deg);
  }
`;
const fadeRight = keyframes`
  from {
    transform: translateY(50px) rotate(2deg);
	opacity: 0;
  }

  to {
	opacity: 1;
    transform: translateY(0) rotate(2deg);
  }
`;
const fadeMobile = keyframes`
  from {
    transform: translateY(50px);
	opacity: 0;
  }

  to {
	opacity: 1;
    transform: translateY(0);
  }
`;

const PreviewContainer = styled.div`
	max-width: 100%;
	overflow: hidden;
	padding: 30px 0;
	margin-bottom: 30px;
	position: relative;
	z-index: 2;
	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		width: 90%;
		height: 1px;
		background-color: #E0E0E0;
		margin-left: auto;
		margin-right: auto;
		left: 0;
		right: 0;
	}
	&.no-border:after {
		height: 0;
	}
	@media (min-width: ${breakpoints.large}) {
		max-width: 1200px;
		margin: 0 auto 30px;
	}
	.gatsby-image-wrapper {
		border-radius: 10px;
		box-shadow: 0px 10px 20px rgba(0,0,0,0.4);
		&:hover {
			cursor: pointer;
		}
	}
	.line-break {
		width: 100%;
		height: 0;
	}
	.album-info {
		max-width: 80%;
		margin: 0 auto;
		padding-top: 40px;
		h2 {
			color: var(--green);
			font-size: 2rem;
			margin-bottom: 16px;
		}
		.link {
			color: var(--orange);
			font-size: .875rem;
			text-decoration: none;
			text-transform: uppercase;
			position: relative;
			display: block;
			svg {
				fill: var(--orange);
				position: absolute;
				left: 100px;
				top: -2px;
			}
		}
	}
	.gatsby-image-wrapper {
		opacity: 0;
	}
	// Preview Layouts ///////////////////////////////////
	// Portrait Left /////
	.portrait-left {
		max-width: 348px;
		margin: 0 auto;
		@media (min-width: ${breakpoints.small}) {
			max-width: 680px;
		}
		@media (min-width: ${breakpoints.medium}) {
			max-width: 850px;
		}
		@media (min-width: ${breakpoints.large}) {
			max-width: 1010px;
		}
		> div:nth-child(1) {
			max-width: 202px;
			margin: 0 0 40px -20px;
			z-index: 1;
			@media (min-width: ${breakpoints.small}) {
				max-width: 245px;
				margin: -20px 0 40px 20px;
				transform: rotate(-2deg);
			}
			@media (min-width: ${breakpoints.medium}) {
				max-width: 285px;
				margin: 0 0 40px 20px;
			}
			@media (min-width: ${breakpoints.large}) {
				max-width: 385px;
				margin: 0 0 40px 20px;
			}
		}
		> div:nth-child(2) {
			max-width: 352px;
			margin: 60px -206px 0px -90px;
			align-self: flex-end;
			/* transform: translateX(-120px) translateY(50px); */
			z-index: 0;
			@media (min-width: ${breakpoints.small}) {
				max-width: 482px;
				margin: -10px 0 0 -68px;
				transform: rotate(2deg);
			}
			@media (min-width: ${breakpoints.medium}) {
				max-width: 542px;
				margin: -10px 0	 0 -20px;
			}
			@media (min-width: ${breakpoints.large}) {
				max-width: 700px;
				margin: 0 0 0 -96px;
			}
		}
	}
	// Portrait Right /////
	.portrait-right {
		max-width: 308px;
		margin: 0 auto;
		@media (min-width: ${breakpoints.small}) {
			max-width: 680px;
		}
		@media (min-width: ${breakpoints.medium}) {
			max-width: 850px;
		}
		@media (min-width: ${breakpoints.large}) {
			max-width: 1010px;
		}
		> div:nth-child(1) {
			max-width: 362px;
			margin: 0 0 0 -84px;
			z-index: 0;
			@media (min-width: ${breakpoints.small}) {
				max-width: 422px;
				margin: 0px 0 0 auto;
				transform: rotate(-2deg);
			}
			@media (min-width: ${breakpoints.medium}) {
				max-width: 522px;
				margin: 20px 0 0 auto;
			}
			@media (min-width: ${breakpoints.large}) {
				max-width: 722px;
				margin: 20px 0 0 auto;
			}
		}
		> div:nth-child(2) {
			width: 200px;
			margin: -170px 0 0 155px;
			z-index: 1;
			@media (min-width: ${breakpoints.small}) {
				max-width: 260px;
				width: auto;
				margin: -330px 0 0 380px;
				transform: rotate(3deg);
			}
			@media (min-width: ${breakpoints.medium}) {
				max-width: 300px;
				width: auto;
				margin: -400px 0 0 480px;
			}
			@media (min-width: ${breakpoints.large}) {
				max-width: 400px;
				width: auto;
				margin: -540px 0 0 620px;
			}
		}
	}
	// Landscap A /////
	.landscape-a {
		max-width: 362px;
		margin: 0 auto;
		@media (min-width: ${breakpoints.small}) {
			max-width: 680px;
		}
		@media (min-width: ${breakpoints.medium}) {
			max-width: 850px;
		}
		@media (min-width: ${breakpoints.large}) {
			max-width: 1010px;
		}
		> div:nth-child(1) {
			max-width: 362px;
			margin: 0 0 0 -60px;
			z-index: 0;			
			@media (min-width: ${breakpoints.small}) {
				max-width: 380px;
				margin-left: auto;
				transform: rotate(-2deg);
			}
			@media (min-width: ${breakpoints.medium}) {
				max-width: 480px;
				margin-left: auto;
			}
			@media (min-width: ${breakpoints.large}) {
				max-width: 580px;
				margin-left: auto;
			}
		}
		> div:nth-child(2) {
			width: 250px;
			margin: -120px 0 0 145px;
			z-index: 1;
			@media (min-width: ${breakpoints.small}) {
				max-width: 380px;
				width: auto;
				margin: -220px 0 0 310px;
				transform: rotate(3deg);
			}
			@media (min-width: ${breakpoints.medium}) {
				max-width: 480px;
				width: auto;
				margin: -280px 0 0 380px;
			}
			@media (min-width: ${breakpoints.large}) {
				max-width: 580px;
				width: auto;
				margin: -280px 0 0 480px;
			}
		}
	}
	// Landscap B /////
	.landscape-b {
		max-width: 362px;
		margin: 0 auto;
		@media (min-width: ${breakpoints.small}) {
			max-width: 680px;
		}
		@media (min-width: ${breakpoints.medium}) {
			max-width: 850px;
		}
		@media (min-width: ${breakpoints.large}) {
			max-width: 1010px;
		}
		> div:nth-child(1) {
			max-width: 305px;
			margin: 30px 0 0 -50px;
			z-index: 1;
			@media (min-width: ${breakpoints.small}) {
				max-width: 380px;
				margin-left: auto;
				transform: rotate(-2deg);
			}
			@media (min-width: ${breakpoints.medium}) {
				max-width: 480px;
				margin-left: auto;
			}
			@media (min-width: ${breakpoints.large}) {
				max-width: 622px;
				margin-left: auto;
			}
		}
		> div:nth-child(2) {
			width: 259px;
			margin: -255px 0 0 170px;
			z-index: 0;
			@media (min-width: ${breakpoints.small}) {
				max-width: 380px;
				width: auto;
				margin: -220px 0 0 310px;
				transform: rotate(3deg);
			}
			@media (min-width: ${breakpoints.medium}) {
				max-width: 480px;
				width: auto;
				margin: -280px 0 0 380px;
			}
			@media (min-width: ${breakpoints.large}) {
				max-width: 622px;
				width: auto;
				margin: -320px 0 0 440px;
			}
		}
	}
	// END Preview Layouts ///////////////////////////////////
	&.visible > .portrait-left {
		div:nth-child(1) { animation: ${fadeMobile} .3s ease-out forwards; }
		div:nth-child(2) { animation: ${fadeMobile} .3s ease-out .2s forwards; }
		@media (min-width: ${breakpoints.small}) {
			div:nth-child(1) { animation: ${fadeLeft} .5s ease-out forwards; }
			div:nth-child(2) { animation: ${fadeRight} .5s ease-out .3s forwards; }
		}
	}
	&.visible > .portrait-right {
		div:nth-child(1) { animation: ${fadeMobile} .3s ease-out .2s forwards; }
		div:nth-child(2) { animation: ${fadeMobile} .3s ease-out forwards; }
		@media (min-width: ${breakpoints.small}) {
			div:nth-child(1) { animation: ${fadeLeft} .5s ease-out .3s forwards; }
			div:nth-child(2) { animation: ${fadeRight} .5s ease-out forwards; }
		}
	}
	&.visible > .portrait-right {
		div:nth-child(1) { animation: ${fadeMobile} .3s ease-out .2s forwards; }
		div:nth-child(2) { animation: ${fadeMobile} .3s ease-out forwards; }
		@media (min-width: ${breakpoints.small}) {
			div:nth-child(1) { animation: ${fadeLeft} .5s ease-out .3s forwards; }
			div:nth-child(2) { animation: ${fadeRight} .5s ease-out forwards; }
		}
	}
	&.visible > .landscape-a {
		div:nth-child(1) { animation: ${fadeMobile} .3s ease-out .2s forwards; }
		div:nth-child(2) { animation: ${fadeMobile} .3s ease-out forwards; }
		@media (min-width: ${breakpoints.small}) {
			div:nth-child(1) { animation: ${fadeLeft} .5s ease-out .3s forwards; }
			div:nth-child(2) { animation: ${fadeRight} .5s ease-out forwards; }
		}
	}
	&.visible > .landscape-b {
			div:nth-child(1) { animation: ${fadeMobile} .3s ease-out .2s forwards; }
			div:nth-child(2) { animation: ${fadeMobile} .3s ease-out forwards; }
		@media (min-width: ${breakpoints.small}) {
			div:nth-child(1) { animation: ${fadeLeft} .5s ease-out .3s forwards; }
			div:nth-child(2) { animation: ${fadeRight} .5s ease-out forwards; }
		}
	}
`




const AlbumPreview = (props) => {

	const { ref, inView, entry } = useInView({
		threshold: .4,
		triggerOnce: true
	  })
	
    return(
		<div ref={ref}>
		<PreviewContainer
			id={props.id}
			className={`${props.className} ${inView ? 'visible': 'hidden' }`}
		>
            <div className={`pics ${props.style}`}>
                <GatsbyImage 
                    image={props.pic1}
                    alt={props.alt1}
                    breakpoints={[386, 770]}
					onClick={() => {
						navigate(props.albumLink);
					}}
                />
                <GatsbyImage
                    image={props.pic2}
                    alt={props.alt2}
                    breakpoints={[386, 770]}
					onClick={() => {
						navigate(props.albumLink);
					}}
                />
            </div>
            <div className="line-break"></div>
            <div className="album-info">
                <h2>{props.title}</h2>
                <Link
					className="link"
					to={props.albumLink}
				>
					View Album
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="15"
						height="20"
						viewBox="0 0 24.78 20"
					>
						<path
							d="M1.239 11.239H20.55l-6.65 6.645a1.239 1.239 0 101.752 1.752l8.761-8.761a1.251 1.251 0 00.154-.189c.021-.031.035-.065.053-.1a1.24 1.24 0 00.06-.116 1.1 1.1 0 00.04-.129c.01-.034.024-.067.031-.1a1.238 1.238 0 00.024-.243 1.249 1.249 0 00-.024-.24c-.007-.037-.021-.072-.032-.108a1.151 1.151 0 00-.038-.123 1.284 1.284 0 00-.064-.123c-.017-.03-.03-.061-.049-.09a1.221 1.221 0 00-.155-.19L15.652.364A1.239 1.239 0 1013.9 2.116l6.646 6.646H1.239a1.239 1.239 0 000 2.478z"
						/>
					</svg>
				</Link>
            </div>
        </PreviewContainer>
		</div>
    )
}

export default AlbumPreview;