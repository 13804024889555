import React from "react";
import styled from "styled-components";
import { Link } from "gatsby"
import scrollTo from "gatsby-plugin-smoothscroll";
import { breakpoints } from "../styles/Breakpoints";
import Logo from "../images/svg/tide-and-tone-logo-white.svg";


const NavContainer = styled.div`
	position: absolute;
	width: 100%;
	max-width: 1400px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	top: 35px;
	right: 0;
	left: 0;
	margin: 0 auto;
	z-index: 8;
	height: 60px;
	.nav-left,
	.nav-right {
		display: flex;
		align-items: center;
	}
	.nav-left {
		button,
		a {
			margin-right: 48px;
			@media (max-width: ${breakpoints.large}) {
				margin-right: 34px;
			}
			@media (max-width: ${breakpoints.medium}) {
				margin-right: 24px;
			}
		}
	}
	.nav-right {
		padding-right: 30px;
		@media (max-width: ${breakpoints.large}) {
			padding-right: 0;
		}
		button,
		a {
			margin-left: 48px;
			@media (max-width: ${breakpoints.large}) {
				margin-left: 34px;
			}
			@media (max-width: ${breakpoints.medium}) {
				margin-left: 24px;
			}
		}
	}
	.svg-logo {
		width: 150px;
		display: block;
		margin: -22px 30px 0;
		@media (min-width: ${breakpoints.medium}) {
			width: 160px;
			margin: 0 10px;
		}
		@media (min-width: ${breakpoints.large}) {
			margin: 0 80px;
		}
	}
	button,
	a {
		display: inline-block;
		color: #fff;
		text-decoration: none;
		padding: 0;
		letter-spacing: 1.5px;
		position: relative;
		z-index: 1;
		font-size: 12px;
		margin-bottom: 8px;
		text-transform: uppercase;
		@media (max-width: ${breakpoints.medSmall}) {
			letter-spacing: 1px;
		}
		@media (max-width: ${breakpoints.small}) {
			margin-left: 15px;
			letter-spacing: 1px;
		}
		&:after {
			content: "";
			position: absolute;
			background-color: var(--blue-light);
			height: 2px;
			top: 25px;
			left: 0;
			width: 0;
			transition: width 0.2s;
		}
		&:hover:after {
			width: 100%;
		}
	}
	a.social {
		display: inline-block;
		width: 20px;
		height: 20px;
		padding: 5px;
		margin-bottom: 8px;
		margin-left: 0;
		border-radius: 50%;
		border: transparent 2px solid;
		&.instagram {
			margin-left: 48px;
		}
		@media (max-width: ${breakpoints.medium}) {
			margin: 0 0 8px 16px;
		}
		svg {
			fill: var(--sand-light);
		}
		&:hover {
			background-color: var(--blue);
		}
		&:after {
			background-color: transparent;
		}
	}
`;

const Nav = () => {
	// function gaEvent(category, label) {
	// 	if (window.gtag) {
	// 		window.gtag("event", "contact_nav", {
	// 			eventAction: "Contact Nav",
	// 			eventCategory: category,
	// 			eventLabel: label,
	// 		});
	// 	}
	// }
    return(
        <NavContainer>
				<div className="nav-left mobile-hide">
					<button
						aria-label="Portfolio Section"
						onClick={() => {
							scrollTo("#portfolio");
							// gaEvent("Navigate", "Portfolio");
						}}
					>
						Portfolio
					</button>
					<Link
						aria-label="Pricing Section"
						to="/pricing"
					>
						Pricing
					</Link>
					<Link
						aria-label="Testimonials Section"
						to="/testimonials"
					>
						Testimonials
					</Link>
				</div>
				<Logo
					className="svg-logo"
					alt="Tide &amp; Tone Photography ::  Wilmington, North Carolina"
				/>
				<div className="nav-right mobile-hide">
					<Link
						aria-label="About Section"
						to="/about"
					>
						About
					</Link>
					<button
						aria-label="Contact Section"
						onClick={() => {
							scrollTo("#contact-section");
							if (window.gtag) {
								window.gtag("event", "contact_nav", {
									eventAction: "Contact Nav"
								});
							}
						}}
					>
						Contact
					</button>
					<a
						rel="noopener"
						href="https://www.instagram.com/tideandtonephoto/"
						target="_blank"
						className="social instagram"
						// onClick={() => gaEvent("External Link", "Instagram")}
					>
						<svg
							id="Layer_1"
							data-name="Layer 1"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
						>
							<defs></defs>
							<path
								className="cls-1"
								d="M12,2.16c3.2,0,3.58,0,4.85.07a6.67,6.67,0,0,1,2.23.42,3.61,3.61,0,0,1,1.38.89,3.61,3.61,0,0,1,.89,1.38,6.67,6.67,0,0,1,.42,2.23c.06,1.27.07,1.65.07,4.85s0,3.58-.07,4.85a6.67,6.67,0,0,1-.42,2.23,3.89,3.89,0,0,1-2.27,2.27,6.67,6.67,0,0,1-2.23.42c-1.27.06-1.65.07-4.85.07s-3.58,0-4.85-.07a6.67,6.67,0,0,1-2.23-.42,3.61,3.61,0,0,1-1.38-.89,3.61,3.61,0,0,1-.89-1.38,6.67,6.67,0,0,1-.42-2.23c-.06-1.27-.07-1.65-.07-4.85s0-3.58.07-4.85a6.67,6.67,0,0,1,.42-2.23,3.61,3.61,0,0,1,.89-1.38,3.61,3.61,0,0,1,1.38-.89,6.67,6.67,0,0,1,2.23-.42C8.42,2.17,8.8,2.16,12,2.16M12,0C8.74,0,8.33,0,7.05.07A8.76,8.76,0,0,0,4.14.63,6.07,6.07,0,0,0,.63,4.14,8.76,8.76,0,0,0,.07,7.05C0,8.33,0,8.74,0,12S0,15.67.07,17a8.76,8.76,0,0,0,.56,2.91,6.07,6.07,0,0,0,3.51,3.51,8.76,8.76,0,0,0,2.91.56C8.33,24,8.74,24,12,24s3.67,0,4.95-.07a8.76,8.76,0,0,0,2.91-.56,6.07,6.07,0,0,0,3.51-3.51A8.76,8.76,0,0,0,23.93,17C24,15.67,24,15.26,24,12s0-3.67-.07-4.95a8.76,8.76,0,0,0-.56-2.91A6.07,6.07,0,0,0,19.86.63,8.76,8.76,0,0,0,17,.07C15.67,0,15.26,0,12,0Zm0,5.84A6.16,6.16,0,1,0,18.16,12,6.16,6.16,0,0,0,12,5.84ZM12,16a4,4,0,1,1,4-4A4,4,0,0,1,12,16ZM19.85,5.59a1.44,1.44,0,1,1-1.44-1.44A1.44,1.44,0,0,1,19.85,5.59Z"
							/>
						</svg>
					</a>
					<a
						rel="noopener"
						href="https://www.facebook.com/tideandtone"
						target="_blank"
						className="social facebook"
						// onClick={() => gaEvent("External Link", "Facebook")}
					>
						<svg
							id="Layer_1"
							data-name="Layer 1"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
						>
							<defs></defs>
							<path className="cls-1" d="M12.34,24h0Z" />
							<path className="cls-1" d="M11.86,24h0Z" />
							<path className="cls-1" d="M12.19,24h0Z" />
							<path
								className="cls-1"
								d="M12,0a12,12,0,0,0-1.87,23.86V15.47H7.08V12h3.05V9.36c0-3,1.79-4.67,4.53-4.67a18.71,18.71,0,0,1,2.68.23v3H15.83a1.74,1.74,0,0,0-2,1.88V12H17.2l-.53,3.47h-2.8v8.39A12,12,0,0,0,12,0Z"
							/>
							<path className="cls-1" d="M11.71,24h0Z" />
							<path className="cls-1" d="M13.41,23.92h0Z" />
							<path className="cls-1" d="M13.55,23.9l-.12,0Z" />
							<path className="cls-1" d="M12.7,24h0Z" />
							<path className="cls-1" d="M13.3,23.93l-.58.05Z" />
						</svg>
					</a>
				</div>
			</NavContainer>
    )
}

export default Nav;