import React from "react";
import scrollTo from "gatsby-plugin-smoothscroll";

export default function BookButton(props) {
	return (
		<button
			// href="mailto:chad@tideandtone.com"
			className={`button light icn-right ${props.slim ? "slim" : ""}`}
			onClick={() => scrollTo("#contact-section")}
		>
			{props.text}
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="24.78"
				height="20"
				viewBox="0 0 24.78 20"
			>
				<path d="M1.239 11.239H20.55l-6.65 6.645a1.239 1.239 0 101.752 1.752l8.761-8.761a1.251 1.251 0 00.154-.189c.021-.031.035-.065.053-.1a1.24 1.24 0 00.06-.116 1.1 1.1 0 00.04-.129c.01-.034.024-.067.031-.1a1.238 1.238 0 00.024-.243 1.249 1.249 0 00-.024-.24c-.007-.037-.021-.072-.032-.108a1.151 1.151 0 00-.038-.123 1.284 1.284 0 00-.064-.123c-.017-.03-.03-.061-.049-.09a1.221 1.221 0 00-.155-.19L15.652.364A1.239 1.239 0 1013.9 2.116l6.646 6.646H1.239a1.239 1.239 0 000 2.478z" />
			</svg>
		</button>
	);
}
