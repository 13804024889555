import * as React from "react";
import styled, { keyframes } from "styled-components";
import { graphql, useStaticQuery, Link } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import scrollTo from "gatsby-plugin-smoothscroll";

import Layout from "../components/Layout";
import Nav from "../components/Nav"
import BookButtonLight from "../components/BookButtonLight";
import AlbumPreview from "../components/AlbumPreview";
import { breakpoints } from "../styles/Breakpoints";

//Sections
// import Contact from "./sections/Contact";
import Toaster from "../components/Toaster";

// styles
const BackgroundOverlay = styled.div `
	background-color: #9F8566;
	opacity: .45;
	width: 100vw;
	height: 100vh;
	position: absolute;
	z-index: 1;
`

const bounce = keyframes`
	0%   { transform: translateY(0); }
	20%   { transform: translateY(0); }
	30%  { transform: translateY(-25px); }
	40%  { transform: translateY(-25px); }
	45%  { transform: translateY(0); }
	50%  { transform: translateY(-8px); }
	55%  { transform: translateY(0); }
	100% { transform: translateY(0); }
`

const MainSection = styled.main`
	padding-top: 100px;
	height: calc(100vh - 120px);
	margin-bottom: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	z-index: 5;
	.book-button {
		display: flex;
		flex-direction: row;
		/* max-width: 200px; */
		font-size: 18px;
		color: #fff;
		border: #fff solid 2px;
		padding: 10px 20px;
		border-radius: 8px;
		text-decoration: none;
		&:hover {
			cursor: pointer;
			background-color: var(--blue);
			border-color: var(--blue);
			svg {
				margin-left: 28px;
				transition: margin-left .2s;
			}
		}
		svg {
			fill: #fff;
			margin: 0 0 0 16px;
		}
	}
	button.scroll-button {
		width: 64px;
		height: 64px;
		position: absolute;
		bottom: 80px;
		animation-duration: 3s;
        animation-iteration-count: infinite;
		transform-origin: bottom;
		&.bounce {
			animation-name: ${bounce};
			animation-timing-function: ease-in;
		}
		svg {
			fill: #fff;
			width: 44px;
			height: 44px;		
		}
	}
`;

const HpIntro = styled.div`
	/* max-width: 304px; */
	margin: 10px auto 0;
	padding: 16px;
	text-align: center;
	position: relative;
	@media (min-width: ${breakpoints.large}) {
		margin-top: 120px;
	}
	h1 {
		font-size: 1.56rem;
		color: #fff;
		margin-bottom: 8px;
		line-height: 1.3;
	}
	p {
		font-size: .775rem;
		color: #fff;
		margin-bottom: 4px;
		text-transform: uppercase;
		letter-spacing: 1px;
		opacity: .7;
		@media (min-width: ${breakpoints.small}) {
			font-size: .875rem;
		}
	}
	&:before {
		content: "";
		position: absolute;
		width: 20px;
		top: 0;
		left: 0;
		height: 100%;
	}
	&:after {
		content: "";
		position: absolute;
		width: 20px;
		top: 0;
		right: 0;
		height: 100%;
	}
`

const StyledCTA = styled(props => <Link {...props} />)`
	width: calc(80% - 50px);
	max-width: 920px;
	margin: 0 auto 30px;
	border-radius: 20px;
	padding: 32px 30px;
	text-decoration: none;
	font-size: 1.825rem;
	font-family: var(--bodoni);
	text-transform: uppercase;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	@media (min-width: ${breakpoints.small}) {
		justify-content: center;
	}
	.nested-flex {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-right: 0;
		@media (min-width: ${breakpoints.small}) {
			flex-direction: row;
			align-items: center;
			margin-right: 40px;
		}
		span {
			font-size: 1.1rem;
			text-transform: none;
			font-style: italic;
			/* font-family: var(--roboto); */
			margin-bottom: 8px;
			margin-right: 0;
			padding: 0;
			border: none;
			letter-spacing: auto;
			opacity: .75;
			@media (min-width: ${breakpoints.small}) {
				margin-bottom: 0;
				margin-right: 20px;
				padding: 6px 0;
				font-size: ,9rem;
				/* border-top: var(--green) 1px solid;
				border-bottom: var(--green) 1px solid; */

			}
			@media (min-width: ${breakpoints.medium}) {
				font-size: 1.1rem;
			}
		}
	}
	svg {
		fill: var(--green);
		margin-left: auto;
		opacity: .45;
		@media (min-width: ${breakpoints.small}) {
			margin-left: 0;
		}
	}
	&.green {
		background-color: var(--green-light);
		color: var(--green);
		svg {
			fill: color: var(--green);
		}
	}
	&.blue {
		background-color: var(--blue-light);
		color: var(--blue-dark);
	}
	&:hover {
		cursor: pointer;
	}
`

const BgFade = styled.div`
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 200px;
	margin-bottom: -120px;
	background: rgb(203,190,175);
	background: linear-gradient(0deg, rgba(203,190,175,0) 0%, rgba(203,190,175,1) 30%, rgba(203,190,175,1) 70%, rgba(203,190,175,0) 100%);
	z-index: 1;
`

const BackgroundImage = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: 0;
  .gatsby-image-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    right: 0;
    img {
      width: 180%;
      width: 300%;
      position: absolute;
      right: 0;
      left: auto;
      @media (min-width: ${breakpoints.medSmall}) {
        width: 150%;
      }
      @media (min-width: ${breakpoints.large}) {
        width: 100%;
      }
    }
  }
`



// markup
const IndexPage = ({location}) => {
	function gaEvent(category, label) {
		if (window.gtag) {
			// window.gtag("event", "click", {
			// 	eventAction: "Main Click",
			// 	eventCategory: category,
			// 	eventLabel: label,
			// });
		}
	}
	const { placeholderImage, previews } = useStaticQuery(
		graphql`
			query {
				placeholderImage: file(
					relativePath: { eq: "site-assets/background.jpg" }
				) {
					childImageSharp {
						gatsbyImageData(
							width: 2000
							placeholder: BLURRED
							quality: 50
							webpOptions: { quality: 70 }
              transformOptions: {fit: CONTAIN, cropFocus: ATTENTION}
						)
					}
				},
				previews: allFile(
					filter: {relativeDirectory: {eq: "albums/previews"}}
					sort: {fields: name}
				) {
					edges {
						node {
							childImageSharp {
							full: gatsbyImageData
							}
						}
					}
				}
			}
		`
	);

	// Array of Preview Images
	const previewsArray = previews.edges.map(({node}) => node.childImageSharp);

	const image = getImage(placeholderImage);
	return (
		<Layout location={location.pathname}>
			<Toaster />
			<Nav />
			<BackgroundOverlay></BackgroundOverlay>
			<MainSection id="main-section">
				<HpIntro>
					<h1>Family &amp; Lifestyle Photography</h1>
					<p>Serving southeastern North Carolina</p>
				</HpIntro>
				<BookButtonLight text="Book your Session" />

				{/* <a className="book-button" href="mailto:chad@tideandtone.com">
					Book Your Session
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="24.78"
						height="20"
						viewBox="0 0 24.78 20"
					>
						<path d="M1.239 11.239H20.55l-6.65 6.645a1.239 1.239 0 101.752 1.752l8.761-8.761a1.251 1.251 0 00.154-.189c.021-.031.035-.065.053-.1a1.24 1.24 0 00.06-.116 1.1 1.1 0 00.04-.129c.01-.034.024-.067.031-.1a1.238 1.238 0 00.024-.243 1.249 1.249 0 00-.024-.24c-.007-.037-.021-.072-.032-.108a1.151 1.151 0 00-.038-.123 1.284 1.284 0 00-.064-.123c-.017-.03-.03-.061-.049-.09a1.221 1.221 0 00-.155-.19L15.652.364A1.239 1.239 0 1013.9 2.116l6.646 6.646H1.239a1.239 1.239 0 000 2.478z" />
					</svg>
				</a> */}
				<button
					className="scroll-button bounce"
					onClick={() => {
						scrollTo("#portfolio");
					}}
					>
					<svg viewBox="0 0 43.6 43.61">
						<path d="M21.8,0A21.81,21.81,0,1,0,43.6,21.8,21.83,21.83,0,0,0,21.8,0Zm0,40.59A18.79,18.79,0,1,1,40.59,21.8,18.81,18.81,0,0,1,21.8,40.59Z"/><path d="M31.33,16.77,21.8,26.3l-9.53-9.53a1.51,1.51,0,0,0-2.13,2.13L20.69,29.44a1.47,1.47,0,0,0,1.06.44h.1a1.49,1.49,0,0,0,1.07-.44L33.46,18.9a1.51,1.51,0,0,0-2.13-2.13Z"/>
					</svg>
				</button>
			</MainSection>
      <BackgroundImage>
        <GatsbyImage className="background-image" image={image} alt="Tide &amp; Tone Background Image"/>
      </BackgroundImage>
			<BgFade></BgFade>
			<div id="portfolio">
				<AlbumPreview
					id="meaneys"
					style="portrait-right"
					pic1={getImage(previewsArray[32].full)}
					pic2={getImage(previewsArray[33].full)}
					alt1="The Meaneys One"
					alt2="The Meaneys Two"
					title="The Meaneys &amp; Friends"
					albumLink="/albums/the-meaneys/"
				/>
				<AlbumPreview
					id="mcgarveys"
					style="portrait-left"
					pic1={getImage(previewsArray[31].full)}
					pic2={getImage(previewsArray[30].full)}
					alt1="The McGarveys One"
					alt2="The McGarveys Two"
					title="The McGarveys"
					albumLink="/albums/the-mcgarveys/"
				/>
				<AlbumPreview
					className="no-border"
					id="zacheisses"
					style="portrait-right"
					pic1={getImage(previewsArray[20].full)}
					pic2={getImage(previewsArray[21].full)}
					alt1="The Zacheisses One"
					alt2="The Zacheisses Two"
					title="The Zacheisses"
					albumLink="/albums/the-zacheisses/"
				/>
				<StyledCTA className="green" to="/pricing">
					<div className="nested-flex">
						<span>Curious about the cost?</span>
						View Pricing
					</div>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="24.78"
						height="20"
						viewBox="0 0 24.78 20"
					>
						<path d="M1.239 11.239H20.55l-6.65 6.645a1.239 1.239 0 101.752 1.752l8.761-8.761a1.251 1.251 0 00.154-.189c.021-.031.035-.065.053-.1a1.24 1.24 0 00.06-.116 1.1 1.1 0 00.04-.129c.01-.034.024-.067.031-.1a1.238 1.238 0 00.024-.243 1.249 1.249 0 00-.024-.24c-.007-.037-.021-.072-.032-.108a1.151 1.151 0 00-.038-.123 1.284 1.284 0 00-.064-.123c-.017-.03-.03-.061-.049-.09a1.221 1.221 0 00-.155-.19L15.652.364A1.239 1.239 0 1013.9 2.116l6.646 6.646H1.239a1.239 1.239 0 000 2.478z" />
					</svg>
				</StyledCTA>
				<AlbumPreview
					id="davises"
					style="portrait-left"
					pic1={getImage(previewsArray[29].full)}
					pic2={getImage(previewsArray[28].full)}
					alt1="The Davises One"
					alt2="The Davises Two"
					title="The Davises"
					albumLink="/albums/the-davises/"
				/>
				<AlbumPreview
					id="sheppards"
					style="portrait-right"
					pic1={getImage(previewsArray[23].full)}
					pic2={getImage(previewsArray[22].full)}
					alt1="The Sheppards One"
					alt2="The Sheppards Two"
					title="The Sheppards"
					albumLink="/albums/the-sheppards/"
				/>
				<AlbumPreview
					className="no-border"
					id="mondays"
					style="portrait-left"
					pic1={getImage(previewsArray[19].full)}
					pic2={getImage(previewsArray[18].full)}
					alt1="The Mondays One"
					alt2="The Mondays Two"
					title="The Mondays"
					albumLink="/albums/the-mondays/"
				/>
				<StyledCTA className="blue" to="/testimonials">
					<div className="nested-flex">
						<span>My customers say it best</span>
						View Testimonials
					</div>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="24.78"
						height="20"
						viewBox="0 0 24.78 20"
					>
						<path d="M1.239 11.239H20.55l-6.65 6.645a1.239 1.239 0 101.752 1.752l8.761-8.761a1.251 1.251 0 00.154-.189c.021-.031.035-.065.053-.1a1.24 1.24 0 00.06-.116 1.1 1.1 0 00.04-.129c.01-.034.024-.067.031-.1a1.238 1.238 0 00.024-.243 1.249 1.249 0 00-.024-.24c-.007-.037-.021-.072-.032-.108a1.151 1.151 0 00-.038-.123 1.284 1.284 0 00-.064-.123c-.017-.03-.03-.061-.049-.09a1.221 1.221 0 00-.155-.19L15.652.364A1.239 1.239 0 1013.9 2.116l6.646 6.646H1.239a1.239 1.239 0 000 2.478z" />
					</svg>
				</StyledCTA>
				<AlbumPreview
					id="knechts"
					style="portrait-right"
					pic1={getImage(previewsArray[27].full)}
					pic2={getImage(previewsArray[26].full)}
					alt1="The Knechts One"
					alt2="The Knechts Two"
					title="The Knechts"
					albumLink="/albums/the-knechts/"
				/>
				<AlbumPreview
					id="fagans"
					style="portrait-left"
					pic1={getImage(previewsArray[16].full)}
					pic2={getImage(previewsArray[17].full)}
					alt1="The Fagans One"
					alt2="The Fagans Two"
					title="The Fagans &amp; Johnsons"
					albumLink="/albums/the-fagans/"
				/>
				<AlbumPreview
					id="alstons"
					style="portrait-right"
					pic1={getImage(previewsArray[24].full)}
					pic2={getImage(previewsArray[25].full)}
					alt1="The Alstons One"
					alt2="The Alstons Two"
					title="The Alstons"
					albumLink="/albums/the-alstons/"
				/>
				<AlbumPreview
					id="dickards"
					className="no-border"
					style="portrait-left"
					pic1={getImage(previewsArray[15].full)}
					pic2={getImage(previewsArray[14].full)}
					alt1="The Dickards One"
					alt2="The Dickards Two"
					title="The Dickards"
					albumLink="/albums/the-dickards"
				/>
				<StyledCTA className="green" to="/pricing">
					<div className="nested-flex">
						<span>Curious about the cost?</span>
						View Pricing
					</div>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="24.78"
						height="20"
						viewBox="0 0 24.78 20"
					>
						<path d="M1.239 11.239H20.55l-6.65 6.645a1.239 1.239 0 101.752 1.752l8.761-8.761a1.251 1.251 0 00.154-.189c.021-.031.035-.065.053-.1a1.24 1.24 0 00.06-.116 1.1 1.1 0 00.04-.129c.01-.034.024-.067.031-.1a1.238 1.238 0 00.024-.243 1.249 1.249 0 00-.024-.24c-.007-.037-.021-.072-.032-.108a1.151 1.151 0 00-.038-.123 1.284 1.284 0 00-.064-.123c-.017-.03-.03-.061-.049-.09a1.221 1.221 0 00-.155-.19L15.652.364A1.239 1.239 0 1013.9 2.116l6.646 6.646H1.239a1.239 1.239 0 000 2.478z" />
					</svg>
				</StyledCTA>
				<AlbumPreview
					id="maynards"
					style="portrait-right"
					pic1={getImage(previewsArray[9].full)}
					pic2={getImage(previewsArray[8].full)}
					alt1="The Maynards One"
					alt2="The Maynards Two"
					title="The Maynards"
					albumLink="/albums/the-maynards"
				/>
				<AlbumPreview
					id="seiverts"
					style="portrait-left"
					pic1={getImage(previewsArray[7].full)}
					pic2={getImage(previewsArray[6].full)}
					alt1="The Seiverts One"
					alt2="The Seiverts Two"
					title="The Seiverts"
					albumLink="/albums/the-seiverts/"
				/>
				<AlbumPreview
					className="no-border"
					id="rayneses"
					style="portrait-right"
					pic1={getImage(previewsArray[3].full)}
					pic2={getImage(previewsArray[2].full)}
					alt1="The Rayneses One"
					alt2="The Rayneses Two"
					title="The Rayneses"
					albumLink="/albums/the-rayneses"
				/>
			 </div>
		</Layout>
	);
};

export default IndexPage;
